import gql from "graphql-tag";

export const GET_ACTIVE_SEQUENCE_QUERY = gql`
query GetActiveSequence {
  activeSequence {
    id
    code
    autoPlay
    numberOfShipments
    completedShipments
    concreteShipments {
      id
      loadingEndTime
      sequenceOrder
      startTime
      code
      quantity
      sealNumber
      waterCut
      concreteFormula {
        id
        code
        fck
        concreteFamily {
          slump {
            nominal
            deviation
          }
        }
      }
      shipmentMaterials {
        humidityMeasurement {
          value
          inventoryItem {
            name
          }
        }
      }
      shipmentInfo {
        constructionAddress
        constructionName
        contractCode
        equipmentPlate
      }
    }
  }
}
`;

const FORMULA_MATERIAL_FRAGMENT = gql`
  fragment FormulaMaterial on FormulaMaterial {
    id
    weight
    order
    inventoryItem {
      id
      name
      materialType
      unit
      inventoryLocations {
        id
        name
        inventoryQuantity
      }
      lastHumidityMeasurement {
        id
        value
      }
    }
  }
`;

export const FORMULA_MATERIAL_TABLE_QUERY = gql`
  query formulaMaterialTable($formulaCode: String!, $quantity: Decimal!) {
    formulaMaterialTable(formulaCode: $formulaCode, quantity: $quantity) {
      water { ...FormulaMaterial }
      sand { ...FormulaMaterial }
      gravel { ...FormulaMaterial }
      cement { ...FormulaMaterial }
      additive { ...FormulaMaterial }
      addition { ...FormulaMaterial }
    }
  }
  ${FORMULA_MATERIAL_FRAGMENT}
`;

export const CONCRETE_FORMULA_QUERY = gql`
  query($formulaCode: Int!) {
    concreteFormula(code: $formulaCode) {
      id
      fck
      use
      concreteFamily {
        id
        gravelType
        slump {
          id
          nominal
          deviation
        }
      }
    }
  }
`;
