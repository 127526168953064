import React from "react";
import ReactSelect from "react-select";
import gql from "graphql-tag";
import { withQuery } from "containers/withQuery";

const FORMULAS_QUERY = gql`
query {
  concreteFormulas(active: true) {
    id
    code
    fck
    concreteFamily {
      slump {
        id
        nominal
        deviation
      }
    }
  }
}
`;

const Select = ({
  value,
  onChange,
  concreteFormulas,
}) => (
  <div style={{ width: "100%" }}>
    <ReactSelect
      className="react-select dark"
      classNamePrefix="react-select"
      name="itemSelected"
      value={value}
      onChange={onChange}
      options={[
        {
          value: "",
          isDisabled: true
        },
        ...concreteFormulas.map(({
          id,
          code,
          fck,
          concreteFamily: {
            slump: {
              nominal,
              deviation
            }
          }
        }) => ({
          value: id,
          code,
          label: `Cód. ${code} | FCK ${fck} | Slump ${nominal} ± ${deviation}`,
        }))
      ]}
      placeholder="Escolha..."
    />
  </div>
);

export default withQuery({
  query: FORMULAS_QUERY
})(Select);
