import Dashboard from "views/Dashboard/Dashboard";
import InstrumentsPanel from "views/InstrumentsPanel/InstrumentsPanel";
import ServiceOrders from "views/ServiceOrders/ServiceOrders";
import ServiceOrderDetail from "views/ServiceOrderDetail/ServiceOrderDetail";
import UmidityScreen from "views/UmidityScreen/UmidityScreen";
import StockMaterials from "views/StockMaterials/StockMaterials";
import FamiliesAndFormulas from "views/FamiliesAndFormulas/FamiliesAndFormulas";
import GeneralRegister from "views/GeneralRegister/GeneralRegister";
import ConciliateShipments from "views/ConciliateShipments/ConciliateShipments";
import ShipmentReport from "views/ShipmentReport/ShipmentReport";
import ControllerSettings from "views/ControllerSettings/ControllerSettings";
import AutomationStatus from "views/AutomationStatus/AutomationStatus";
import Login from "views/Login/Login";
import Schedule from "views/Schedule/Schedule";

const onlineRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin"
  },
  {
    redirect: true,
    path: "/service_orders/:id",
    component: ServiceOrderDetail,
    layout: "/admin"
  },
  {
    path: "/service_orders",
    name: "Ordens de Serviço",
    icon: "nc-icon nc-box",
    component: ServiceOrders,
    layout: "/admin"
  },
  {
    path: "/conciliate_shipments",
    name: "Conciliar carregamentos",
    icon: "nc-icon nc-delivery-fast",
    component: ConciliateShipments,
    layout: "/admin"
  },
];

const offlineRoutes = [
  {
    path: "/instruments_panel",
    name: "Painel de Instrumentos",
    icon: "nc-icon nc-chart-bar-32",
    component: InstrumentsPanel,
    layout: "/admin"
  },
  {
    path: "/schedule",
    name: "Programação",
    icon: "nc-icon nc-calendar-60",
    component: Schedule,
    layout: "/admin"
  },
  {
    path: "/shipment_report",
    name: "Relatório de carga",
    icon: "nc-icon nc-chart-pie-36",
    component: ShipmentReport,
    layout: "/admin"
  },
  {
    path: "/umidity",
    name: "Umidades",
    icon: "nc-icon nc-sound-wave",
    component: UmidityScreen,
    layout: "/admin"
  },
  {
    path: "/stock_materials",
    name: "Materiais em Estoque",
    icon: "nc-icon nc-tag-content",
    component: StockMaterials,
    layout: "/admin"
  },
  {
    path: "/families_formulas",
    name: "Famílias e traços",
    icon: "nc-icon nc-vector",
    component: FamiliesAndFormulas,
    layout: "/admin"
  },
  {
    path: "/general_registers",
    name: "Cadastros Gerais",
    icon: "nc-icon nc-send",
    component: GeneralRegister,
    layout: "/admin"
  },
  {
    path: "/automation_status",
    name: "Status da Automação",
    icon: "nc-icon nc-settings-gear-65",
    component: AutomationStatus,
    layout: "/admin"
  },
];

const adminRoutes = [
  {
    path: "/controller_settings",
    name: "Configurações",
    icon: "nc-icon nc-settings",
    component: ControllerSettings,
    layout: "/admin"
  },
];

const loginRoutes = [
  {
    path: "/login",
    name: "Login",
    mini: "L",
    component: Login,
    isHidden: true,
    layout: "/auth"
  },
]

const baseRoutes = [
  {
    path: "/instruments_panel",
    name: "Painel de Instrumentos",
    icon: "nc-icon nc-chart-bar-32",
    component: InstrumentsPanel,
    layout: "/admin"
  },
  {
    path: "/shipment_report",
    name: "Relatório de carga",
    icon: "nc-icon nc-chart-pie-36",
    component: ShipmentReport,
    layout: "/admin"
  },
  {
    path: "/automation_status",
    name: "Status da Automação",
    icon: "nc-icon nc-settings-gear-65",
    component: AutomationStatus,
    layout: "/admin"
  },
];

const routes = userRole => (
  (userRole === "ADMIN")
  ? [...offlineRoutes, ...adminRoutes, ...loginRoutes]
  : (userRole === "API_USER")
  ? [...baseRoutes, ...loginRoutes]
  : [...offlineRoutes, ...loginRoutes]
);

export default routes;
