import React, {
  useState,
  useEffect,
  useCallback,
  useRef
} from "react";
import { useMutation } from "@apollo/client";
import { useQuery } from "@apollo/react-hooks";
import { httpClient } from "settings/apolloClient";
import styled from "styled-components";
import {
  Card,
  Col,
  Container,
  Row,
} from "reactstrap";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import {
  GET_ACTIVE_SEQUENCE_QUERY,
} from "./graphql/scheduleQueries";
import {
  UPDATE_ACTIVE_SEQUENCE_MUTATION,
} from "./graphql/scheduleMutations";
import { ShipmentList } from "./ShipmentList";
import { ShipmentInfo } from "./ShipmentInfo";
import { SequenceInfo } from "./SequenceInfo";
import { ShipmentCreateModal } from "./ShipmentCreateModal";
import { ShipmentEditModal } from "./ShipmentEditModal";

const POLL_INTERVAL = 3000;

const CardContainer = styled(Card)`
  width: 100%;
`;
const CardHeader = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin: 0;
    text-align: left;
  }

  .auto-play-toggle {
    display: flex;
    align-items: center;
  }

  h5 {
    margin: 0;
    text-align: right;
    padding-left: 5px;
  }
`;

const AutoPlayToggle = ({ sequence, refetchSequence }) => {
  const [autoPlay, setAutoPlay] = useState(false);
  const [updateSequence] = useMutation(UPDATE_ACTIVE_SEQUENCE_MUTATION, { client: httpClient });

  const handleToggle = useCallback(() => {
    updateSequence({
      variables: {
        autoPlay: !autoPlay,
      },
    }).then(() => {
      refetchSequence();
    });
  }, [updateSequence, autoPlay, refetchSequence]);

  useEffect(() => {
    if (Boolean(sequence)) {
      setAutoPlay(sequence.autoPlay);
    };
  }, [sequence]);

  return (
    <Toggle
      defaultChecked={autoPlay}
      checked={autoPlay}
      onClick={() => handleToggle()}
      icons={{
        unchecked: null,
      }}
    />
  );
};

const TooltipContainer = styled.div`
  position: relative;
`;

const Tooltip = styled.div`
  position: absolute;
  top: -10px;
  left: 100%;
  width: 300px;
  padding: 5px 10px;
  background-color: black;
  color: white;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 1100;

  &.show {
    opacity: 1;
  }
`;

const TooltipTrigger = styled.div`
  cursor: pointer;
  height: 20px;
  width: 20px;
`;

const TooltipComponent = ({ children, tooltipTexts }) => {
  const tooltipRef = useRef(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  return (
    <TooltipContainer>
      <TooltipTrigger onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </TooltipTrigger>
      <Tooltip ref={tooltipRef} className={isTooltipVisible ? "show" : ""}>
        {
          tooltipTexts.map((text, index) => (
            <div key={index}>{text}</div>
          ))
        }
      </Tooltip>
    </TooltipContainer>
  );
};

const QuestionIcon = styled.div`
  background-color: black;
  color: white;
  font-size: 12px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 5px;
`;

function Schedule() {
  const [sequence, setSequence] = useState(null);
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [createShipmentModalOpen, setCreateShipmentModalOpen] = useState(false);
  const [editShipmentModalOpen, setEditShipmentModalOpen] = useState(false);
  const [selectedFormula, setSelectedFormula] = useState(null);

  const {
    data: activeSequenceData,
    refetch,
    startPolling,
  } = useQuery(GET_ACTIVE_SEQUENCE_QUERY, {
    fetchPolicy: "network-only",
    pollInterval: POLL_INTERVAL,
    client: httpClient,
  });

  const { activeSequence } = activeSequenceData || {};

  useEffect(() => {
    if (activeSequence) {
      setSequence(activeSequence);
    }
  }, [activeSequence]);

  useEffect(() => {
    startPolling(POLL_INTERVAL);
  }, []);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="content">
      <Container fluid>
        <Row>
          <Col sm={12} md={8}>
            <CardContainer>
              <CardHeader>
                <p
                  className="text-left"
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#000",
                  }}
                >
                  Próximas cargas
                </p>
                <div className="auto-play-toggle">
                  <AutoPlayToggle sequence={sequence} refetchSequence={refetch} />
                  <h5>Rodar automaticamente</h5>
                  <TooltipComponent
                    tooltipTexts={[
                      "Quando ativado, a próxima carga será iniciada imediatamente após o término da carga atual.",
                      "Caso não tenha nenhuma carga em andamento, iniciará a primeira carga da programação abaixo."
                    ]}
                  >
                    <QuestionIcon>?</QuestionIcon>
                  </TooltipComponent>
                </div>
              </CardHeader>
              <ShipmentList
                refetchSequence={refetch}
                selectedShipment={selectedShipment}
                sequence={sequence}
                setSelectedShipment={setSelectedShipment}
              />
            </CardContainer>
          </Col>
          <Col sm={12} md={4}>
            {
              Boolean(sequence) && (
                <SequenceInfo
                  refetchSequence={refetch}
                  sequence={sequence}
                  setCreateShipmentModalOpen={setCreateShipmentModalOpen}
                  setSequence={setSequence}
                />
              )
            }
            {
              Boolean(selectedShipment) && (
                <ShipmentInfo
                  refetchSequence={refetch}
                  setSelectedFormula={setSelectedFormula}
                  setEditShipmentModalOpen={setEditShipmentModalOpen}
                  setSelectedShipment={setSelectedShipment}
                  shipment={selectedShipment}
                />
              )
            }
          </Col>
        </Row>
      </Container>
      {
        createShipmentModalOpen && (
          <ShipmentCreateModal
            refetchSequence={refetch}
            setCreateShipmentModalOpen={setCreateShipmentModalOpen}
          />
        )
      }
      {
        editShipmentModalOpen && (
          <ShipmentEditModal
            refetchSequence={refetch}
            selectedCode={selectedShipment.code}
            selectedFormula={selectedFormula}
            selectedId={selectedShipment.id}
            selectedQuantity={selectedShipment.quantity}
            selectedSealNumber={selectedShipment.sealNumber}
            setEditShipmentModalOpen={setEditShipmentModalOpen}
          />
        )
      }
    </div>
  );
};

export default Schedule;
