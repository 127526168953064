import React from "react";
import { Query, compose, graphql } from "react-apollo";
import PlaceholderImg from "assets/img/operator-placeholder.jpg";
import Toggle from "react-toggle";
import Spinner from "react-spinkit";
import "react-toggle/style.css";
import {
  Container,
  OperatorCard,
  Title,
  Info,
  Operator,
  Photo,
  Name,
  SpinnerContainer,
} from "./authorizedOperatorComponents";
import {
  AUTHORIZED_OPERATOR_QUERY,
  REQUEST_OPERATOR_ACCESS_MUTATION,
  REMOVE_OPERATOR_ACCESS_MUTATION
} from "./authorizedOperatorQueries";

const AuthorizedOperator = ({ requestOperatorAccess, removeOperatorAccess }) => (
  <Container>
    <OperatorCard className="operator-card">
      <Query query={AUTHORIZED_OPERATOR_QUERY} fetchPolicy="network-only" pollInterval={2000}>
        {({ loading, error, data }) => {
          if (loading) {
            return (
              <SpinnerContainer>
                <Spinner name="line-spin-fade-loader" color="green" fadeIn="none" />
              </SpinnerContainer>
            );
          }

          if (error) {
            return <Title>Erro ao carregar operador autorizado.</Title>;
          }

          const operator = data.getCurrentAutomationOperator;
          const user = data.getCurrentUser;
          return (
            <React.Fragment>
              <Title>Operador Autorizado</Title>
              <Info className="operator-info">
                <Operator className="operator-img-wrapper">
                  <Photo src={PlaceholderImg} />
                  {operator && <Name>{operator.name}</Name>}
                </Operator>
                <Toggle
                  disabled={operator && operator.id !== user.id}
                  icons={{
                    unchecked: null,
                  }}
                  checked={!!operator}
                  onChange={() => {
                    if (operator) {
                      removeOperatorAccess({
                        refetchQueries: [{ query: AUTHORIZED_OPERATOR_QUERY }]
                      });
                    } else {
                      requestOperatorAccess({
                        refetchQueries: [{ query: AUTHORIZED_OPERATOR_QUERY }]
                      });
                    }
                  }}
                />
              </Info>
            </React.Fragment>
          );
        }}
      </Query>
    </OperatorCard>
  </Container>
);

export default compose(
  graphql(REQUEST_OPERATOR_ACCESS_MUTATION, { name: "requestOperatorAccess", }),
  graphql(REMOVE_OPERATOR_ACCESS_MUTATION, { name: "removeOperatorAccess", }),
)(AuthorizedOperator);
