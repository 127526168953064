import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { httpClient } from "settings/apolloClient";
import {
  CHANGE_SEQUENCE_ORDER_MUTATION,
} from "./graphql/scheduleMutations";
import ArrowDown from "assets/img/arrow-down.svg";
import ArrowDownDisabled from "assets/img/arrow-down-disabled.svg";
import ArrowUp from "assets/img/arrow-up.svg";
import ArrowUpDisabled from "assets/img/arrow-up-disabled.svg";

const TableContainer = styled.div`
  overflow: scroll;
  width: 100%;
  border-bottom: 1px solid #F6F8FF;
  border-radius: 15px;
`;

const Table = styled.table`
  width: 100%;
`;

const TableRow = styled.tr`
  width: 100%;
  border-bottom: 1px solid #cfd1d3;
  cursor: pointer;

  &.selected {
    background-color: #edeff2;
  };

  &:hover {
    background-color: #cfd1d3;
  };
`;

const Header = styled.th`
  width: 100%;
  background-color: #F4F6F8;
  padding-top: 22px;
  padding-bottom: 22px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: ${props => props.center ? "center" : "left"};
`;

// Sequence order column should be a colum that has 2 arrows to move the order up or down,
// if the shipment was already started, the arrows should be disabled,
// if it is the first non-started shipment, the up arrow should be disabled
// if it is the last non-started shipment, the down arrow should be disabled
// these arrows should call a mutation to update the order of the shipments
const SequenceOrder = styled.td`
  padding: 14px 16px 14px 16px;
  font-size: 13px;
  color: #77889D;
  text-align: center;
`;

const SingleLine = styled.td`
  padding: 14px 16px 14px 16px;
  font-size: 16px;
  color: #8798AD;
  text-align: left;
  font-weight: bold;
`;

// Formula column should be a column with 2 lines,
// the first line should be the formula code it should have a bigger font size and a bold font weight,
// the second line should be the slump and fck, it should have a smaller font size and a normal font weight
const DoubleLine = styled.td`
  padding: 14px 16px 14px 16px;
  font-size: 13px;
  color: #8798AD;
  text-align: left;
`;

const ArrowButton = styled.button`
  background-color: #E9E9E9;
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    background-color: #F4F6F8;
    cursor: not-allowed;
  }
  
  &:focus {
    outline: 0;
  }

  img {
    width: 24px;
    height: 24px; // Adjust icon size as needed
  }
`;

const Status = styled.p`
  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};
  padding: 5px;
  margin: auto;
  border-radius: 10px;
  text-align: center;
  font-weight: bold;
`;

const TableHeader = [
  { code: "orderAction", label: "", width: "5%" },
  { code: "sequenceOrder", label: "Ordem", width: "7%" },
  { code: "formula", label: "Traço", width: "30%" },
  { code: "code", label: "Identificação", width: "30%" },
  { code: "quantity", label: "Volume", width: "10%" },
  { code: "status", label: "Status", width: "15%" },
  { code: "shipmentAction", label: "", width: "3%" },
];

const ShipmentOrderAction = ({
  id,
  startTime,
  sequenceOrder,
  isLastShipment,
  isFirstPendingShipment,
  refetchSequence,
}) => {
  const [changeSequenceOrder] = useMutation(CHANGE_SEQUENCE_ORDER_MUTATION, { client: httpClient });

  const arrowUpDisabled = isFirstPendingShipment || Boolean(startTime);
  const arrowDownDisabled = isLastShipment || Boolean(startTime);

  const handleChangeOrder = useCallback((direction) => {
    const newOrder = (direction === "up") ? sequenceOrder - 1 : sequenceOrder + 1;
    changeSequenceOrder({
      variables: {
        concreteShipmentId: id,
        currentOrder: sequenceOrder,
        newOrder,
      },
    }).then(() => {
      refetchSequence();
    })
  }, [changeSequenceOrder, refetchSequence, id, sequenceOrder]);

  return (
    <SequenceOrder>
      <ArrowButton
        disabled={arrowUpDisabled}
        onClick={() => handleChangeOrder("up")}
      >
        <img src={arrowUpDisabled ? ArrowUpDisabled : ArrowUp} alt="arrow up" />
      </ArrowButton>
      <ArrowButton
        disabled={arrowDownDisabled}
        onClick={() => handleChangeOrder("down")}
      >
        <img src={arrowDownDisabled ? ArrowDownDisabled : ArrowDown} alt="arrow down" />
      </ArrowButton>
    </SequenceOrder>
  );
};

const ShipmentOrderColumn = ({ sequenceOrder }) => {
  return (
    <SingleLine>
      {sequenceOrder}
    </SingleLine>
  );
};

const ShipmentFormula = ({ concreteFormula }) => {
  const code = concreteFormula.code;
  const fck = concreteFormula.fck;
  const nominal = concreteFormula.concreteFamily.slump.nominal;
  const deviation = concreteFormula.concreteFamily.slump.deviation;
  const slump = `${nominal} ± ${deviation}`;
  return (
    <DoubleLine>
      <div
        style={{
          fontSize: "16px",
          fontWeight: "bold",
        }}
      >
        {code}
      </div>
      <div>{`${slump} | Fck: ${fck} MPa`}</div>
    </DoubleLine>
  );
};

const ShipmentIdentification = ({ code, sealNumber }) => {
  return (
    <DoubleLine>
      <div
        style={{
          fontSize: "16px",
          fontWeight: "bold",
        }}
      >
        {code}
      </div>
      <div>{`Lacre: ${sealNumber}`}</div>
    </DoubleLine>
  );
};

const ShipmentQuantity = ({ quantity }) => {
  return (
    <SingleLine>
      {`${quantity} m³`}
    </SingleLine>
  );
};


const ShipmentStatus = ({ startTime, loadingEndTime }) => {
  const status = loadingEndTime ? "Finalizada" : startTime ? "Em andamento" : "Aguardando";
  const backgroundColor = loadingEndTime ? "#DCF6E5" : startTime ? "#FEF2D6" : "#D6F4F9";
  const textColor = loadingEndTime ? "#2D8D57" : startTime ? "#B76E01" : "#1C6C9C";
  return (
    <td>
      <Status backgroundColor={backgroundColor} textColor={textColor}>
        {status}
      </Status>
    </td>
  ); 
};

const ShipmentRow = ({
  shipment,
  isFirstPendingShipment,
  isLastShipment,
  setSelectedShipment,
  selectedShipment,
  refetchSequence,
}) => {
  const {
    id,
    sequenceOrder,
    concreteFormula,
    code,
    quantity,
    sealNumber,
    startTime,
    loadingEndTime,
  } = shipment;

  const [className, setClassName] = useState("");

  const handleSelectShipment = () => {
    if (Boolean(selectedShipment) && selectedShipment.id === id) {
      setSelectedShipment(null);
    } else {
      setSelectedShipment(shipment);
    };
  };

  useEffect(() => {
    if (Boolean(selectedShipment) && selectedShipment.id === id) {
      setClassName("selected");
    } else {
      setClassName("");
    }
  }, [selectedShipment]);

  return (
    <TableRow
      key={id}
      className={className}
      onClick={() => handleSelectShipment()}
    >
      <ShipmentOrderAction
        id={id}
        sequenceOrder={sequenceOrder}
        startTime={startTime}
        isLastShipment={isLastShipment}
        isFirstPendingShipment={isFirstPendingShipment}
        refetchSequence={refetchSequence}
      />
      <ShipmentOrderColumn sequenceOrder={sequenceOrder} />
      <ShipmentFormula concreteFormula={concreteFormula} />
      <ShipmentIdentification code={code} sealNumber={sealNumber} />
      <ShipmentQuantity quantity={quantity} />
      <ShipmentStatus startTime={startTime} loadingEndTime={loadingEndTime} />
    </TableRow>
  );
};

const getFirstPendingShipmentId = (shipments) => {
  if (Boolean(shipments) && shipments.length > 0) {
    const firstPendingShipment = shipments.find((shipment) => Boolean(shipment) && !shipment.startTime);
    return !firstPendingShipment ? -1 : firstPendingShipment.id;
  }
  return -1;
};

export function ShipmentList({
  sequence,
  selectedShipment,
  setSelectedShipment,
  refetchSequence,
}) {
  const [shipments, setShipments] = useState(null);
  const [firstPendingShipmentId, setFirstPendingShipmentId] = useState(null);

  useEffect(() => {
    if (Boolean(sequence) && Boolean(sequence.concreteShipments) && sequence.concreteShipments.length > 0) {
      setShipments(sequence.concreteShipments);
      setFirstPendingShipmentId(getFirstPendingShipmentId(sequence.concreteShipments));
    };
  }, [sequence]);

  return (
    <TableContainer>
      <Table>
        <thead>
          <tr>
            {TableHeader.map(({ code, label, width }) => (
              <Header key={code} center={code === "sequenceOrder"} style={{ width }}>
                {label}
              </Header>
            ))}
          </tr>
        </thead>
        <tbody>
          {Boolean(shipments) && shipments.map((shipment, index) => {
            if (!Boolean(shipment)) return null;
            const isFirstPendingShipment = shipment.id === firstPendingShipmentId;
            const isLastShipment = index === (shipments.length - 1);
            return (
              <ShipmentRow
                shipment={shipment}
                isFirstPendingShipment={isFirstPendingShipment}
                isLastShipment={isLastShipment}
                setSelectedShipment={setSelectedShipment}
                selectedShipment={selectedShipment}
                refetchSequence={refetchSequence}
              />
            );
          })}
          {
            ((Boolean(shipments) && shipments.length === 0) || !Boolean(shipments)) && (
              <TableRow>
                <td colSpan={TableHeader.length}>
                  <SingleLine>
                    Nenhuma carga foi programada para este sequenciamento ainda.
                  </SingleLine>
                </td>
              </TableRow>
            )
          }
        </tbody>
      </Table>
    </TableContainer>
  );
};
