import gql from "graphql-tag";

export const CHANGE_SEQUENCE_ORDER_MUTATION = gql`
mutation ChangeSequenceOrder($concreteShipmentId: Int!, $currentOrder: Int!,$newOrder: Int!) {
  changeSequenceOrder(id: $concreteShipmentId, currentOrder: $currentOrder, newOrder: $newOrder) {
    id
    code
  }
}
`;

export const UPDATE_ACTIVE_SEQUENCE_MUTATION = gql`
mutation UpdateActiveSequence($autoPlay: Boolean!) {
  updateActiveSequence(params:{ autoPlay: $autoPlay }) {
    id
    code
    autoPlay
  }
}
`;

export const FINISH_ACTIVE_SEQUENCE_MUTATION = gql`
mutation FinishActiveSequence {
  finishActiveSequence {
    id
    code
    autoPlay
  }
}
`;

export const CHANGE_ACTIVE_SEQUENCE_CODE_MUTATION = gql`
mutation ChangeActiveSequenceCode($code: String!) {
  changeActiveSequenceCode(code: $code) {
    id
    code
    autoPlay
  }
}
`;

export const CANCEL_CONCRETE_SHIPMENT_MUTATION = gql`
mutation CancelConcreteShipment($id: Int!) {
  cancelConcreteShipment(id: $id) {
    id
    code
  }
}
`;
